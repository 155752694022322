





























































import RedNotification from "@/models/RedNotification";
import { Component, Vue } from "vue-property-decorator";
import { ActionMethod } from 'vuex';
import { Action, State, Getter } from 'vuex-class';

@Component({})
export default class NotificationsPage extends Vue {
  public loading: Boolean = true
  public displayModal: Boolean = false
  private notification: RedNotification = new RedNotification()

  @State(state => state.redNotifications.notifications) notifications: RedNotification[];

  @Getter('context/getConsumerName') consumerName: string;
  @Getter('redNotifications/getCategories') categories: string[];

  @Action('fetchNotifications', { namespace: 'redNotifications' }) fetchNotifications: ActionMethod;
  @Action('updateNotifications', { namespace: 'redNotifications' }) updateNotifications: ActionMethod;
  @Action('deleteNotification', { namespace: 'redNotifications' }) deleteNotification: ActionMethod;
  @Action('fetchCategories', { namespace: 'redNotifications' }) fetchCategories: ActionMethod;

  async mounted() {
    try {
      await this.fetchCategories()
      await this.fetchNotifications()
    } catch (err) {
      this.$notify({ type: 'error', text: err.message })
    } finally {
      this.loading = false
    }
  }

  openModal(item: RedNotification) {
    console.log(">> item", item)
    this.notification = item || new RedNotification()
    this.displayModal = true
  }

  submit() {
    var p1 = this.updateNotifications(this.notification)
    Promise.all([p1]).then((result: any) => {
      this.$notify({ type: 'success', text: 'Notifications successfully updated' })
      this.displayModal = false
    }).catch(err => {
      this.$notify({ type: 'error', text: err.message })
    });
  }

  confirmRemoveNotification(notification: RedNotification) {
    this.$confirm.show({
      buttonText: "Remove",
      title: "Remove receiver",
      text: "Do you really want to removethat receiver?",
      type: 'warning',
      onConfirm: () => {
        this.deleteNotification(notification).then(result => {
          this.$notify({
            type: 'success', text: `Receiver removed`
          })
        }).catch(err => {
          this.$notify({
            type: 'error', text: err.message
          })
        }).finally(() => {
          this.$confirm.hide()
        })
      }
    });
  }
}
